@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  font-family: 'Inter';
}

/* .App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  font-size: calc(10px + 1vmin);
  color: black;
}


.item {
  margin-top: 10px;
  margin-bottom: 2px;
  font-family: 'Avenir Next';
  font-weight: 500;
  text-align: left;
  font-size: .8rem;
  --bs-gutter-x: 0;
}

.row-item {
  margin-left: 10px;
  padding-right: 0;
}

.custom-row {
  width: 66.66667%;
}

.heading {
  margin-top: 5vh;
  font-size: 1.5rem !important;
  align-self: flex-start;
  font-family: 'Zilla-Slab' !important;
}


.custom-nav {
  height: 8vh;
  margin-left: 2vh;
  border-color: black;
  border-bottom-width: 5px;
  padding-top: 4px;
}

.button {
  margin-top: 24px;
  
  background-color: #233759 !important;
}

.divider {
  border-top: 1px solid black;
  width: 200rem;
  margin-top: 0.2rem;
  margin-bottom: 2rem;
}

.datepicker .react-datepicker-wrapper{
  display: inherit;
} */



/* @media (max-width: 767px) {

  .item {
    margin-left: 5vw !important;
  }

  .custom-row {
    width: 100%;
  }

  .col-md-8 {
    width: 80%;
    margin-left: 0px !important;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

} */


/* CSS for the Card component */
/* .custom-card {
  width: 35vw;
  display: flex;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 24px;
}

.checkmark {
  flex: 0 0 auto;
  margin-right: 3vw;
  margin-left: 6vw;
}

.card-content {
  
  flex: 1;
  margin-right: 5vw;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  color: black;
}

.info {
  font-size: 14px;
  color: #555;
} */

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}